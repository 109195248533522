<template>
    <div class="company_login" :style="background">
        <div class="shanxi_content" v-if="location == '陕西省'">
            <div class="title">
                <img class="shanxi_title" src="~/static/shanxi/logo (1).png" />
            </div>
            <div class="login">
                <h1 class="name">
                    企业登录/注册
                </h1>
                <div class="login_content flex">
                    <loginAll shadow="boxShadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.12)" status="2" type="2"></loginAll>
                </div>
            </div>
        </div>
        <div class="content" v-else>
            <div class="title">
                <h3>兰州市残疾人就业创业网络服务平台 | 用户登录</h3>
                <router-link to="/">返回首页</router-link>
            </div>
            <div class="login">
                <h1 class="name">
                    企业登录/注册
                </h1>
                <div class="login_content flex">
                    <loginAll shadow="boxShadow: 0px 6px 30px 0px rgba(0, 0, 0, 0.12)" status="2" type="2"></loginAll>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import loginAll from "../components/login/loginAll";
    export default {
        name: "company_login",
        components:{loginAll},
        data(){
            return{
                background:'',
                location:'',
            }
        },
        methods:{
            backgrounds(){
                let num =  Math.floor(Math.random()*4);
                this.background = 'backgroundImage:url('+require('@/static/login/pic'+num+'.jpg')+')';
            }
        },
        created() {
            this.location = localStorage.getItem("locationSite");
            this.backgrounds();
        }
    }
</script>

<style scoped lang="less">
    .company_login{
        width: 100%;
        min-height: 100vh;
        background-color:white;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: 100% 366px;
        .content{
            width: 1200px;
            margin: 0 auto;
            .title{
                display: flex;
                padding-top: 26px;
                h3{
                    flex: 1;
                    font-size: 18px;
                    color: #28A46D;
                }
                a{
                    font-size: 18px;
                    color: #28A46D;
                }
                a:hover{
                    color: #333;
                }
            }
            .login{
                width: 408px;
                justify-content: center;
                margin: 120px auto 0 auto;

                h1{
                    text-align: center;
                    font-size: 28px;
                    color: #00120A;
                    margin-bottom: 36px;
                }
                /deep/ .login_is{
                        border-radius: 12px;
                    }

            }
        }
        .shanxi_content{
            width: 1200px;
            margin: 0 auto;
            background: url(~static/shanxi/loginbg.png) center center no-repeat;
            width: 100%;
            min-height: 100vh;
            background-size: 100% 100%;
            padding: 54px 0 50px 0;
            .title{
                text-align: center;
                padding-top: 30px;
                img{

                }
            }
            .login{
                width: 408px;
                justify-content: center;
                margin: 60px auto 0 auto;

                h1{
                    text-align: center;
                    font-size: 28px;
                    color: #00120A;
                    margin-bottom: 36px;
                }
                /deep/ .login_is{
                        border-radius: 12px;
                    }

            }
        }
    }
</style>
